import {computed, reactive, ref, toRaw} from "vue";
import adoptModel from "@/api/adopt";
import siteinfo from "@/util/global";
import common from "@/api/common";
import moment from "moment";
import tool from "@/util/tool";

//认养列表数据
export function useAdopt(search){
    let adoptState = reactive({
        list:[],
        page:1,
        limit:10,
        count:0
    })

    function getAdoptList(page,limit){
        adoptModel.getAdoptList(page, limit, search, res => {
            adoptState.list = res.list
            adoptState.page = res.page
            adoptState.limit = res.limit
            adoptState.count = res.count
        })
    }

    const deleteAdopt = id=>adoptModel.deleteAdopt(id, () => getAdoptList(adoptState.page,adoptState.limit))

    return{ adoptState,getAdoptList,deleteAdopt }
}

//认养信息统计
export function useAdoptStatistics(){
    let asState = ref(null)
    const getAdoptStatistics = ()=>adoptModel.getAdoptStatistics(res => asState.value = res)

    getAdoptStatistics()

    return{ asState,getAdoptStatistics }
}

//认养项目特点设置
export function useAdoptFeature(){
    let afState = reactive({
        show:false,
        form:[],
        adopt_id:0,
    })

    function showFeature(adopt_id){
        console.log('1111')
        afState.show = true
        afState.adopt_id = adopt_id
        console.log(afState.show);
        adoptModel.getAdoptFeatures(adopt_id, res => afState.form = res)
    }

    function addOrDelFeature(index) {
        if (index <= -1) {
            if (!afState.form || afState.form.length == 0) afState.form = []
            afState.form.push({
                img: "",
                name: "",
                summary: ""
            })
            return
        }
        afState.form.splice(index, 1)
    }

    const saveFeature = ()=>{
        adoptModel.saveAdoptFeature(afState.form, afState.adopt_id, () => afState.show = false )
    }
    return{ afState,showFeature,saveFeature,addOrDelFeature }
}

//认养项目流程设置
export function useAdoptFlow(){
    let flowState = reactive({
        show:false,
        adopt_id:0,
        form:{
            status:2,
            flowinfo:[]
        }
    })

    function showFlow(adopt_id){
        flowState.adopt_id = adopt_id
        flowState.show = true
        adoptModel.getFlowDetail(adopt_id, res => flowState.form = res)
    }

    function addOrDelFlow(index) {
        if (index <= -1) {
            flowState.form.flowinfo.push({
                img: "",
                name: "",
                summary: "",
                time: [],
                imgs: [],
            })
            return
        }
        flowState.form.flowinfo.splice(index, 1)
    }

    const saveFlow = ()=>adoptModel.saveAdoptFlow(flowState.form, flowState.adopt_id, () => {
        flowState.show = false
    })

    return{ flowState ,showFlow,saveFlow,addOrDelFlow }
}

//认养问题设置
export function useAdoptIssue(){
    let aiState = reactive({
        show:false,
        adopt_id:0,
        form:{}
    })
    function showIssue(adopt_id){
        aiState.show = true
        aiState.adopt_id = adopt_id
        adoptModel.getFlowDetail(adopt_id, res => aiState.form = res)
    }

    function addOrDelIssue(index) {
        if (index <= -1) {
            aiState.form.qa_array.push({
                ask_title: "",
                asnwer: "",
            })
            return
        }
        aiState.form.qa_array.splice(index, 1)
    }

    const saveIssue = ()=>adoptModel.saveAdoptIssue(aiState.form,aiState.adopt_id,()=>aiState.show = false)

    return{aiState,showIssue ,addOrDelIssue,saveIssue}
}

//认养排名设置
export function useAdoptRank(){
    let arState = reactive({
        show:false,
        adopt_id:0,
        form:{}
    })

    function showRank(adopt_id){
        arState.show = true
        arState.adopt_id = adopt_id
        adoptModel.getFlowDetail(adopt_id, res => arState.form = res)
    }

    const saveRank = ()=>adoptModel.saveAdoptSort(arState.form, arState.adopt_id, () => arState.show = false)

    return{ arState,showRank,saveRank }
}

//认养环境
export function useAdoptEnvironment(){
    let aeState = reactive({
        show:false,
        form:{},
        adopt_id:0,
        map:false,
    })

    function showEnvironment(adopt_id){
        aeState.show = true
        aeState.adopt_id = adopt_id
        adoptModel.getAdoptArea(adopt_id, res => aeState.form = res)
    }

    const saveEnvironment = ()=>{
        adoptModel.saveAdoptEnvironment(aeState.form, aeState.adopt_id, () => {
            aeState.show = false
        })
    }

    function getMapArea(e) {
        aeState.form.area.area = e.area
        aeState.form.area.point = e.point
        if (e.point.length > 0) {
            aeState.form.area.lng = e.point[0][0]
            aeState.form.area.lat = e.point[0][1]
        }
        aeState.map = false
    }
    return{ aeState ,showEnvironment,saveEnvironment,getMapArea }
}

//认养项目周期
export function useAdoptCycle(){
    let acState = reactive({
        show:false,
        list:[],
        adopt_id:0,
        qrcode:"",
        showQrcode:false,
        project_id:0,   //项目id（周期iD）
    })

    let cycleStatus = computed(()=>{
        return function (status,is_txt){
            if (status === 1) return is_txt ? '报名中' : '#00CCCC'
            if (status === 2) return is_txt ? '认养中' : '#00CCCC'
            if (status === 3) return is_txt ? '配送中' : '#FF0066'
            if (status === 4) return is_txt ? '已完成' : '#00CC66'
        }
    })

    function getAdoptCycleList(adopt_id){
        acState.adopt_id = adopt_id
        adoptModel.getAdoptCycle(adopt_id, res => {
            acState.list = res
            acState.show = true
        })
    }
    const copyAdoptCycle = adopt_id=> adoptModel.copyAdopt(adopt_id ,() => getAdoptCycleList(adopt_id))

    function showAdoptCycleQrcode(adopt_id, qrcode) {
        acState.project_id = adopt_id
        acState.qrcode = qrcode
        acState.showQrcode = true
    }

    function downloadQrcode() {
        let path = `/api/admin/AdoptdownloadQrcode?token=${localStorage.getItem('token')}&adopt_id=${acState.project_id}`
        let url = siteinfo.siteinfo.siteroot + path
        window.open(url);
    }

    /**
     * 改变周期状态
     * @param project_id    周期ID
     * @param status        状态 1报名中 2认养中 3配送中 4已完成
     * @param adopt_id
     */
    function changeAdoptCycleStatus(project_id,status,adopt_id){
        adoptModel.changeAdoptCycleStatus(project_id, status, () => getAdoptCycleList(adopt_id))
    }

    function changeAdoptCyclePutAway(status, id, adopt_id){
        adoptModel.changeAdoptPutAway(id, status == 1 ? 0 : 1, () => getAdoptCycleList(adopt_id))
    }

    const deleteAdoptCycle = (id,adopt_id)=> adoptModel.deleteAdopt(id, () => getAdoptCycleList(adopt_id))

    return{
        acState,
        getAdoptCycleList,
        copyAdoptCycle ,
        cycleStatus,
        downloadQrcode,
        showAdoptCycleQrcode,
        changeAdoptCycleStatus,
        changeAdoptCyclePutAway,
        deleteAdoptCycle
    }
}

//认养分类列表
export function useAdoptCategory(search){
    let acState = reactive({
        list:[],
        page:1,
        count:0,
        limit:10,
        search:{key:""},
        pks:[],
    })

    function getAdoptCategory(page,limit){
        adoptModel.getAdoptCategroy(page,limit,acState.search,res=>{
            acState.list = res.list
            acState.page = res.page
            acState.count = res.count
            acState.limit = res.limit
        })
    }

    function deleteAdoptCategory(id){
        common.deleteDataList(id,50,"确认删除该认养分类吗").then(()=>{
            getAdoptCategory(acState.page,acState.limit)
        })
    }
    return{ acState,getAdoptCategory,deleteAdoptCategory }
}

//认养分类新增、编辑
export function useEditAdoptCategory(state,fn){
    let eacState = reactive({
        show:false,
        form:null
    })

    function showEditCategory(data){
        eacState.form = {
            id:data ?data.id :0,
            name:data ?data.name:"",
            rank:data ?data.rank:99,
            status:data ?data.status :0,
        }
        eacState.show = true
    }

    function saveAdoptCategory(){
        adoptModel.addOrEditCategory( toRaw(eacState.form),()=>{
            eacState.show = false
            if( typeof fn == 'function') fn(state.page,state.limit)
        })
    }

    return{ eacState,saveAdoptCategory ,showEditCategory}
}

//认养订单信息
export function useAdoptOrder(){
    let aoState = reactive({
        list:[],
        page:1,
        limit:10,
        count:0,
        search:{
            key:'',
            selectType:'1',
            searchTime:[],
            order_status:'-1',
        },
    })
    function getAdoptOrder(page,limit){
        adoptModel.getAdoptOrder(page,limit,aoState.search,res=> {
            aoState.list = res.list
            aoState.page = res.page
            aoState.count = res.count
            aoState.limit = limit
        })
    }

    return{ aoState,getAdoptOrder }
}

//订单状态修改 、打印订单、下载合同
export function useChangeAdoptOrderStatus(state,fn){

    function refreshPage(){
        if( typeof fn !='function' ) return
        if( state ){
            fn(state.page,state.limit)
            return;
        }
        fn()
    }

    function changeAdoptOrderStatus(id,type){
        adoptModel.setAdoptOrderStatus(id,type,()=>refreshPage())
    }

    const printAdoptOrder = id=>common.printOrder(id,3)

    const downloadContract = id=>adoptModel.getContractOrder(id)

    return {changeAdoptOrderStatus,printAdoptOrder,downloadContract}
}

//修改认养预计成熟时间
export function useEditAdoptRipeTime(fn){
    let eartState = reactive({
        show:false,
        ripe_time:"",
        pk:0,
    })

    function showRipeTime(data){
        eartState.show = true
        eartState.pk = data.id
        eartState.ripe_time = moment(data.ripe_time)
    }

    function saveRipeTime(){
        let param = { pk:eartState.pk,ripe_time:eartState.ripe_time }
        adoptModel.setAdoptOrderDetailInfo('ripe-time',param,()=>{
            eartState.show = false
            if(typeof fn == 'function') fn()
        })
    }

    return{ eartState,showRipeTime,saveRipeTime }
}

//修改认养编号
export function useEditAdoptNumber(fn){
    let eanState = reactive({
        show:false,
        pk:0,
        adopt_number:'',
    })
    function showAdoptNumber(data) {
        eanState.show = true
        eanState.pk = data.id
        eanState.adopt_number = data.adopt_number
    }

    function saveAdoptNumber(){
        let data = { pk:eanState.pk,adopt_number:eanState.adopt_number }
        adoptModel.setAdoptOrderDetailInfo('adopt-number',data,()=>{
            eanState.show = false
            if(typeof fn == 'function') fn()
        })
    }

    function changeAdoptStatus(pk,adoption_status){
        adoptModel.setAdoptOrderDetailInfo('status',{pk,adoption_status},()=>fn() )
    }

    return { eanState,showAdoptNumber,saveAdoptNumber ,changeAdoptStatus}
}

//认养状态跟踪
export function useAdoptStatusTail(){
    let astState = reactive({
        list:[],
        show:false,
        detail_id:0,

        showAdd:false,
        form:{},
        adoptServer:[]
    })

    function showAdoptStatusList(id){
        astState.detail_id = id
        astState.show = true
        adoptModel.getAdoptStatus(id,res=>astState.list = res )
    }

    function showAddAdoptStatus(data,adopt_id){
        astState.form = {
            adopt_id:adopt_id,
            order_id:data.id,
            maturity:"",
            images:[],
            server_id:0,
            server_detail_id:0,
            remark:"",
            cascaderValue:[]
        }
        astState.showAdd = true
        adoptModel.getAdoptServe(adopt_id,res=>astState.adoptServer = res)
    }

    function changeServer(e){
        astState.form.server_id = e[0]
        astState.form.server_detail_id = e[1]
    }

    function saveAdoptStatus(){
        adoptModel.addOrEditAdoptStatus(toRaw(astState.form),()=>astState.showAdd = false)
    }

    const deleteAdoptStatus = id=>{
        common.deleteDataList(id,33,'确认删除认养跟踪状态信息吗?').then(()=>showAdoptStatusList(astState.detail_id))
    }

    return{ astState,showAdoptStatusList ,showAddAdoptStatus,saveAdoptStatus,changeServer,deleteAdoptStatus}
}

//认养配送信息
export function useAdoptOrderDelivery(state,fn){
    let aodState = reactive({
        showIds:[], //展开的周期
        show:false,
        form:{
            lng:"",
            lat:"",
            name:"",
            phone:"",
            address:"",
            detail_id:0,
            order_id:0,
        }
    })

    function showDelivery(index){
        let {detail} = state.detail

        //手动展示
        if( index >= 0 ){
            let id = detail[index].id

            if( aodState.showIds.includes(id) ){
                aodState.showIds.splice(aodState.showIds.indexOf(id),1)
                detail[index].show_delivery = false
            }else{
                aodState.showIds.push(id)
                detail[index].show_delivery = true
            }

            state.detail.detail = detail
            return
        }

        if( index === -1 ){
            detail.forEach(item=>{
                item.show_delivery = false
                if( aodState.showIds.includes(item.id) ){
                    item.show_delivery = true
                }
                item.statistics = getAdoptCurrent(item.delivery)
            })
            state.detail.detail = detail
            return
        }
    }

    function getAdoptCurrent(data){
        if( !data || data.length == 0 ) return
        let len = data.length , current_adopt = 0 ,complete = 0 //已完成
        for (let i = 0 ;i<len;i++ ){
            if( data[i].delivery_status == 0 ){
                current_adopt = data[i].id
                break
            }else{
                complete+=1
            }
        }
        return {cycle_id:current_adopt,complete,all:len,reside:len-complete }
    }

    function showAddDelivery(order_id,detail_id){
        let orderInfo = toRaw(state.detail ) , add = orderInfo.address_info
        if( typeof add == 'string'){
            add = JSON.parse(add)
        }
        aodState.form.lng = add.lng
        aodState.form.lat = add.lat
        aodState.form.name = add.name
        aodState.form.phone = add.phone
        aodState.form.address = add.address
        aodState.form.order_id = order_id
        aodState.form.detail_id = detail_id
        aodState.show = true
    }

    function saveAdoptDelivery(){
        adoptModel.createAdoptCycle(toRaw(aodState.form),()=>{
            aodState.show = false
            if( typeof fn == 'function') fn()
        })
    }

    return{ aodState,showDelivery,showAddDelivery,saveAdoptDelivery }
}

//认养订单退款
export function useAdoptOrderRefund(fn){
    let aorState = reactive({
        show:false,
        price:0
    })

    function sureRefund(order_id){
        adoptModel.adoptOrderRefund(aorState.price,order_id,()=>{
            aorState.show = false
            if( typeof fn == 'function') fn()
        })
    }

    return { aorState,sureRefund }
}

//认养订单操作日志
export function useAdoptOrderLog(order_id){
    let aolState = reactive({
        list:[],
    })
    common.getOrderLog('adopt_order',order_id,res=>aolState.list = res)
    return{aolState}
}

//认养配送订单
export function useAdoptDelivery(){
    let adState = reactive({
        search:{ delivery_status:-1},
        ids:[],
        list:[],
        page:1,
        limit:10,
        count:0
    })

    function getAdoptDelivery(page,limit){
        adoptModel.getAdoptDeliveryOrder(page,limit,adState.search,res=> {
            adState.list = res.list
            adState.page = res.page
            adState.limit = limit
            adState.count = res.count
        })
    }
    const batchDelivery = url =>adoptModel.adoptDeliveryOrderBatch(url)

    const exportOrder = ()=>adoptModel.exportAdoptSendOrder(adState.ids)

    function batchConfirm(){
        adoptModel.setAdoptOrderStatus(adState.ids,'batch-confirm',()=>{
            getAdoptDelivery(adState.page,adState.limit)
        })
    }

    function confirmAdoptDelivery(id){
        adoptModel.setAdoptOrderStatus(id,3,()=>{
            getAdoptDelivery(adState.page,adState.limit)
        })
    }

    return { adState ,getAdoptDelivery,batchDelivery,batchConfirm,exportOrder,confirmAdoptDelivery}
}

//认养合同列表
export function useAdoptContract(){
    let acState = reactive({
        search:{key:""},
        list:[],
        page:1,
        limit:10,
        count:0
    })

    function getAdoptContractList(page,limit){
        adoptModel.getAdoptContract(page,limit,acState.search,res=> {
            acState.list = res.list
            acState.page = res.page
            acState.count = res.count
            acState.limit = limit
        })
    }

    return { acState ,getAdoptContractList}
}

//用户认养合同
export function useAdoptContractRecord(){
    let acrState = reactive({
        show:false,
        contract_id:0,
        list:[],
        page:1,
        limit:10,
        count:0,
    })

    function showContractRecord(id){
        acrState.contract_id = id
        acrState.show = true
        acrState.list = []
        getContractRecord(1,acrState.limit)
    }

    function getContractRecord(page,limit){
        let { contract_id } = acrState
        adoptModel.getContractUser(page,limit,{contract_id},res=>{
            acrState.list = res.list
            acrState.page = res.page
            acrState.count = res.count
        })
    }

    const downloadContract = id=>adoptModel.getContractOrder(id,()=>{})

    return{ acrState,showContractRecord,getContractRecord,downloadContract }
}

//查看合同内容
export function useLookContract(){
    let lcState = reactive({
        show:false,
        info:null
    })

    /**
     * 查看合同内容
     * @param data  全部的合同
     * @param id    查看的合同id
     */
    function lookContract(data,id){
        if( !tool.returnMessage(id,'请选择合同')) return
        if( !data || data.length === 0 ) return;
        lcState.show = true
        for (let i = 0; i < data.length; i++) {
            if( data[i].id === id ){
                lcState.info = data[i]
                return;
            }
        }
    }

    return { lcState,lookContract }
}























